.modal2 {
  position: fixed;
top: 0%;
left: 0;
width: calc(100vw - 18px);
height: 100%;
background: #000000b8;
z-index: 99999;
}
.select-item {
  font-weight: normal !important;
}

.dropdown-content {
  position: absolute !important;
z-index: 99991 !important;
top: 100%;
width: 100%;
padding-top: 8px;
font-weight: normal !important;
}

.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px; 
}


.fullwidthdropdown {
  width: 100% !important
}


#dropdown .dropdown-content {
position: relative !important;
z-index: 99991 !important;
top: 100%;
width: 100%;
padding-top: 8px;
font-weight: normal !important;
}


.rmsc .search {
  height: unset !important;

}


.fq-modal-content {

  margin: 3% auto !important; }


  .fq-modal-header-pics {
    background: #BE031200;
    position: relative;
    min-height: 95px;
    padding-top: 16px;
    padding-bottom: 21px;
    text-align: center;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.fq-modal-1 .close {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  cursor: pointer;
}


.fq-modal-1 {
  display: none;
  position: fixed;
  z-index: 100002;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #707070;
  background-color: rgba(70, 69, 69, 0.94);
  z-index: 999999;
}

.BrainhubCarousel__dots {
  justify-content: center !important;
  display: flex !important;
  margin-top: auto !important;
  padding-bottom: 40px !important;
}

.PicturesLeft {
  font-size: 52px;
  color: #194A96;
  cursor: pointer;
}

.PicturesRight {
  font-size: 52px;
  color: #194A96;
  cursor: pointer;

}




.BrainhubCarousel {

  position: relative;
}



.BrainhubCarousel__arrowLeft {
  display: flex;
  
  position: absolute !important;
}

.BrainhubCarousel__arrowRight {
  position: absolute !important;
  right: 0;
  display: flex;
  margin-right: auto;
}

.BrainhubCarousel__arrows {
  position: relative;
  padding: 21px;
  border: none;
  overflow: hidden;
  outline: 0;
  font-size: 0;
  line-height: 0;
  background-color: unset !important;
 
}

.BrainhubCarousel__arrows {

  z-index: 100;
}

.BrainhubCarousel__arrows span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-color: #194A96 !important;
  border-width: 3px 3px 0 0;
  padding: 5px;
  transition: 0.3s;
  font-size: 0;
}


.ImgModalPic {
  max-width: 90vw;
max-height: 60vh;
}


.fontTrainingNumber {
  font-size: 45px;
  font-weight: 100;
  line-height: 1;
  text-align: center;
}

.fontTrainingPeople {
  font-size: 15px;
  font-weight: 100;
  text-align: center;
  text-transform: capitalize;
}


.flexTraining {
  width: 30%;
display: flex;
flex-direction: column;
justify-content: center;
}


.imgfill {
  width: 100%;
height: 100%;
object-fit: cover;
}


.fillcontainer {
  width: 100%;
height: 140px;
}


.img-text-block .cta-link {

  margin-left: 0 !important; 
}


.mixingleft {
  top: 0% !important; 
}

.mixingright {
  top: 0% !important; 
}




.font-color {
  color: unset !important;
}

.font-color-a{
  color: #194A96 !important;
}


.backgroundwhite {
  background-color: white !important;
  padding-bottom: 10px;
}

.somecontent {
  padding: 16px;
}



.centered-text-overlay {
  position: absolute;
  color: #fff !important;
  font-weight: bold;
  font-size: 22px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) rotate(-25deg);
  z-index: 1000;
}


.overlay-img {
  position: absolute;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.31);
  z-index: 2;
  cursor: pointer;
  height: 140px;
  margin-bottom: 0;
  margin-top: 0;
}
.overlay-img.full {
  height: 100%
}





.pc_section > div {

 box-sizing: unset !important;
 line-height: 1;
} 



:root {
  --corner_color: rgba(0, 0, 0, 0.1);
}
.main_content {
  margin: 80px 0px 0px 150px;
  border-top: 6px solid #194a96;
  border-right: 6px solid #194a96;
  border-bottom: 6px solid #194a96;
  width: 1003px;
  border-radius: 2px;
  z-index: 1;
  position: relative;
  height: 270px;
  border-radius: 0px 200px 200px 0px;
}
.container2 {
  margin-top: -8px;
  margin-left: 98px;
  border-left: 6px solid #194a96;
  border-bottom: 6px solid #194a96;
  width: 1003px;
  border-radius: 2px;
  z-index: 1;
  position: relative;
  height: 270px;
  border-radius: 200px 0px 0px 200px;
}
.key {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #194a96;
  padding: 10px;
}
.key_bag {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -47px;
  left: -90px;
  right: 0px;
  bottom: 0px;
}
.key_bag1 {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 491px;
  left: 957px;
  right: 0px;
  bottom: 0px;
}
.text {
  color: white;
  font-size: 20px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin: 28px 0px 21px 8px;
  display: block;
  line-height: 1;
}
.circle_key {
  background-color: #194a96;
}
.key_bag img {
  margin-top: 21px;
}
.key_bag1 img {
  margin-top: 21px;
}
.circle_1 {
  background-color: #1cc435;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -45px;
  left: 103px;
  right: 0px;
  bottom: 0px;
}
.circle_2 {
  background-color: #eddc15;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -45px;
  left: 288px;
  right: 0px;
  bottom: 0px;
}
.circle_3 {
  background-color: #be0312;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -45px;
  left: 462px;
  right: 0px;
  bottom: 0px;
}
.circle_4 {
  background-color: #1cc435;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -45px;
  left: 646px;
  right: 0px;
  bottom: 0px;
}
.circle_5 {
  background-color: #eddc15;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -45px;
  left: 826px;
  right: 0px;
  bottom: 0px;
}
.circle_6 {
  background-color: #1cc435;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 83px;
  left: 946px;
  right: 0px;
  bottom: 0px;
}
.circle_7 {
  background-color: #1cc435;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 216px;
  left: 842px;
  right: 0px;
  bottom: 0px;
}
.circle_8 {
  background-color: #be0312;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 216px;
  left: 667px;
  right: 0px;
  bottom: 0px;
}
.circle_9 {
  background-color: #eddc15;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 216px;
  left: 456px;
  right: 0px;
  bottom: 0px;
}
.circle_10 {
  background-color: #1cc435;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 216px;
  left: 227px;
  right: 0px;
  bottom: 0px;
}

.circle_12 {
  background-color: #eddc15;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -44px;
  left: 29px;
  right: 0px;
  bottom: 0px;
}
.circle_13 {
  background-color: #1cc435;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 97px;
  left: -48px;
  right: 0px;
  bottom: 0px;
}
.circle_14 {
  background-color: #be0312;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 214px;
  left: 48px;
  right: 0px;
  bottom: 0px;
}
.circle_15 {
  background-color: #eddc15;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 214px;
  left: 243px;
  right: 0px;
  bottom: 0px;
}
.circle_16 {
  background-color: #1cc435;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 214px;
  left: 448px;
  right: 0px;
  bottom: 0px;
}
.circle_17 {
  background-color: #eddc15;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 214px;
  left: 645px;
  right: 0px;
  bottom: 0px;
}
.circle_18 {
  background-color: #be0312;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 214px;
  left: 828px;
  right: 0px;
  bottom: 0px;
}

.right_corner_1 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: -32px;
  left: 33px;
  right: 0px;
  bottom: 0px;
}
.right_corner_2 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 0px;
  left: 33px;
  right: 0px;
  bottom: 0px;
}
.right_corner_3 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: -32px;
  left: 227px;
  right: 0px;
  bottom: 0px;
}
.right_corner_4 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 0px;
  left: 227px;
  right: 0px;
  bottom: 0px;
}
.right_corner_5 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: -31px;
  left: 406px;
  right: 0px;
  bottom: 0px;
}
.right_corner_6 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 0px;
  left: 406px;
  right: 0px;
  bottom: 0px;
}
.right_corner_7 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: -31px;
  left: 586px;
  right: 0px;
  bottom: 0px;
}
.right_corner_8 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 0px;
  left: 586px;
  right: 0px;
  bottom: 0px;
}
.right_corner_9 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: -31px;
  left: 764px;
  right: 0px;
  bottom: 0px;
}
.right_corner_10 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 0px;
  left: 764px;
  right: 0px;
  bottom: 0px;
}
.right_corner_11 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 25px solid var(--corner_color);
  position: absolute;
  top: 245px;
  left: 791px;
  right: 0px;
  bottom: 0px;
}
.right_corner_12 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-right: 25px solid var(--corner_color);
  position: absolute;
  top: 276px;
  left: 791px;
  right: 0px;
  bottom: 0px;
}
.right_corner_13 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 25px solid var(--corner_color);
  position: absolute;
  top: 245px;
  left: 583px;
  right: 0px;
  bottom: 0px;
}
.right_corner_14 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-right: 25px solid var(--corner_color);
  position: absolute;
  top: 276px;
  left: 583px;
  right: 0px;
  bottom: 0px;
}
.right_corner_15 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 25px solid var(--corner_color);
  position: absolute;
  top: 245px;
  left: 372px;
  right: 0px;
  bottom: 0px;
}
.right_corner_16 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-right: 25px solid var(--corner_color);
  position: absolute;
  top: 276px;
  left: 372px;
  right: 0px;
  bottom: 0px;
}
.right_corner_17 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 25px solid var(--corner_color);
  position: absolute;
  top: 245px;
  left: 135px;
  right: 0px;
  bottom: 0px;
}
.right_corner_18 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-right: 25px solid var(--corner_color);
  position: absolute;
  top: 276px;
  left: 135px;
  right: 0px;
  bottom: 0px;
}
.right_corner_19 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 544px;
  left: 732px;
  right: 0px;
  bottom: 0px;
}
.right_corner_20 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 513px;
  left: 732px;
  right: 0px;
  bottom: 0px;
}
.right_corner_21 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 544px;
  left: 901px;
  right: 0px;
  bottom: 0px;
}
.right_corner_22 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 513px;
  left: 901px;
  right: 0px;
  bottom: 0px;
}
.right_corner_23 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 544px;
  left: 335px;
  right: 0px;
  bottom: 0px;
}
.right_corner_24 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 513px;
  left: 335px;
  right: 0px;
  bottom: 0px;
}
.right_corner_25 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 544px;
  left: 538px;
  right: 0px;
  bottom: 0px;
}
.right_corner_26 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 513px;
  left: 538px;
  right: 0px;
  bottom: 0px;
}
.right_corner_27 {
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 544px;
  left: 133px;
  right: 0px;
  bottom: 0px;
}
.right_corner_28 {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 25px solid var(--corner_color);
  position: absolute;
  top: 513px;
  left: 133px;
  right: 0px;
  bottom: 0px;
}

.title_text_1 {
  position: absolute;
  top: 47px;
  left: 94px;
  right: 0px;
  bottom: 0px;
}
.title_text_2 {
  position: absolute;
  top: 47px;
  left: 267px;
  right: 0px;
  bottom: 0px;
}
.title_text_3 {
  position: absolute;
  top: 47px;
  left: 426px;
  right: 0px;
  bottom: 0px;
}
.title_text_4 {
  position: absolute;
  top: 47px;
  left: 628px;
  right: 0px;
  bottom: 0px;
}
.title_text_5 {
  position: absolute;
  top: 47px;
  left: 794px;
  right: 0px;
  bottom: 0px;
}
.title_text_6 {
  position: absolute;
  top: 111px;
  left: 794px;
  right: 0px;
  bottom: 0px;
}
.title_text_7 {
  position: absolute;
  top: 309px;
  left: 822px;
  right: 0px;
  bottom: 0px;
}
.title_text_8 {
  position: absolute;
  top: 309px;
  left: 656px;
  right: 0px;
  bottom: 0px;
}
.title_text_9 {
  position: absolute;
  top: 309px;
  left: 445px;
  right: 0px;
  bottom: 0px;
}
.title_text_10 {
  position: absolute;
  top: 309px;
  left: 212px;
  right: 0px;
  bottom: 0px;
}
.title_text_11 {
  position: absolute;
  top: 316px;
  left: -2px;
  right: 0px;
  bottom: 0px;
}
.title_text_13 {
  position: absolute;
  top: 118px;
  left: 44px;
  right: 0px;
  bottom: 0px;
}
.title_text_14 {
  position: absolute;
  top: 306px;
  left: 34px;
  right: 0px;
  bottom: 0px;
}
.title_text_15 {
  position: absolute;
  top: 306px;
  left: 225px;
  right: 0px;
  bottom: 0px;
}
.title_text_16 {
  position: absolute;
  top: 306px;
  left: 430px;
  right: 0px;
  bottom: 0px;
}
.title_text_17 {
  position: absolute;
  top: 306px;
  left: 630px;
  right: 0px;
  bottom: 0px;
}
.title_text_18 {
  position: absolute;
  top: 306px;
  left: 817px;
  right: 0px;
  bottom: 0px;
}
.manag {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 98px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag2 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 102px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag3 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 147px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag4 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 117px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag5 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 148px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag6 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 144px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag7 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 117px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag8 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 117px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag9 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 117px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag10 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 117px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag11 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 117px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag13 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 117px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag14 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 114px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag15 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 114px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag16 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 114px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag17 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 114px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.manag18 {
  background-color: #ffffff;
  color: #8d93a2;
  padding: 5px;
  width: 114px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}


.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.buttonPrev {
  background-color: #F0742D;
border: none;
color: white;
padding: 15px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 19px;
margin: 4px 2px;
    margin-left: 2px;
cursor: pointer;
border-radius: 12px;
margin-left: 10px;
}


.iSprayBooth {margin-right:15px}


.noshadow-left {

  -webkit-box-shadow: unset !important;
box-shadow: unset !important;
min-width: 255px !important;
max-width: 255px!important;
margin-left: 5px;
}


.noshadow-right {
  min-width: 255px !important;
max-width: 255px!important;
margin-right: 5px;

  -webkit-box-shadow: unset !important;
box-shadow: unset !important;
}





.ImgModalPic {
  max-width: 90vw;
max-height: 60vh;
}


.snakegreen {
background-color: #1cc435 !important;
z-index: 1000;
cursor: pointer;
}

.snakedarkgreen {
  background-color: #1F8E30 !important;
  z-index: 1000;
  cursor: pointer;
  }


.snakered {
  background-color: #F99F1C !important;
  z-index: 1000;
  cursor: pointer;
  }

  
.snakeorange {
  background-color: #BE0312 !important;
  z-index: 1000;
  cursor: pointer;
  }


  .progressred {
    background-color: #BE0312 !important
  }

  .progressorange {
    background-color: #F99F1C !important
  }

  .progressgreen {
    background-color: #1cc435 !important
  }


  .cols-small {
    width:50%
  }


.minheightImg {
  min-height: 140px;
}

.sameheighttitle {
  height:  48px;
}


.mg-t-225 {
  margin-top: 16px !important
  ;
  }

  .ProjectImage {
    border-radius: 50%;
width: 120px;
height: 120px;
object-fit: cover;
  }



.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1.5s linear infinite; 
  animation: spin 1.5s linear infinite;
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.SnakeMargin {
  margin: 27px 0px 14px 0px;
}


.BrainhubCarousel__dots {

  overflow: scroll
}


.noPics {
  pointer-events: none;
}

.fillcontainer .item{
  cursor: pointer;
}

.container2 {
  z-index: unset!important;
}


.redSelect {
  color: red !important
}

.fq-modal-content {
  max-height: 90vh;
overflow-x: clip;
}

.modal-content-wrapper{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 75vh;
  overscroll-behavior: contain;
}

.fq-modal .close {
  position: absolute;
  right: 0px;
  top: 0px;
  opacity: 1;
  z-index: 1000;
}


.carduser {
  font-size: 14px !important;
font-weight: normal !important;
}

.noborder {
  border-radius: 0px;
}


.centered-text-overlay-bay {
  position: absolute;
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
  font-size: 25px;
  transform: rotate(-43deg);
  top: 40%;
  left: 20%;
  z-index: 1000;
}


::placeholder { 
  color: rgb(220, 220, 220);
  opacity: 1; 
}

:-ms-input-placeholder {
  color: rgb(220, 220, 220);
}

::-ms-input-placeholder { 
  color: rgb(220, 220, 220);
}

.saveButtonTasklist {
  font-size: 15px;
  background-color: #194A96;
  color: #fff;
  padding: 8px 16px;
  border-radius: 18px;
}

.btn.delete {
  color: #BE0312;
  background-color: transparent;
  text-transform: uppercase;
}

.btn.edit {
  color: #194a96;
  background-color: transparent;
  text-transform: uppercase;
}

.imgtasklistcontainer {
  padding: 8px
}

.imgtasklist {
  border-radius: 20px;
}


input[type="file"] {
  display: none;
}

.greenIMG {
color: #1cc435 !important
}
.RedIMG{
  color: #d9534f !important

}
.markedTasklist {
  border :1px solid #194A96 !important;
  color: #194A96 !important;
  }

  .div-done-blue {
    color: #194A96 !important;
    }
  


    .whiteColor {
      background-color: white !important;
color: #194a96 !important;
    }

   .bg-grey {
     background-color:rgba(0, 0, 0, 0.31)
   }

   .TaskListRow {
    
    margin-top: 15px;
    margin-bottom: 15px;
   }
   .TaskListRowParent {
    
    padding-top: 5px;
    padding-bottom: 5px;
   }



   .borderSecure {
    border-top: 1px solid #d3d3d3;
    width: 200%;
    margin-left: -50%;
   

   }


   .carbrandpicture {
max-height:50px;
max-width: 50px;
   }


  .tooltiptextOne {
    visibility: visible;
    
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 50%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltiptextOne::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }


  .img-text-block, .img-car-block {
 
    border-radius: 16px !important;
  
}

.projectButton {
  display: flex;
  margin: auto;
  width: 100px;
  height: 50px;
  align-items: center;
justify-content: center;
}


.projectButtonLoading {
  display: flex;
  margin: auto;
  width: 100px;
  height: 50px;
  align-items: center;
justify-content: center;
color:grey;
cursor: not-allowed;
}

.loaderTwo {
  border: 5px solid #f3f3f3;
  border-top-color: rgb(243, 243, 243);
  border-top-style: solid;
  border-top-width: 5px;
border-radius: 50%;
border-top: 5px solid #3498db;
width: 120px;
height: 120px;
animation: spin 1.5s linear infinite;
width: 40px !important;
height: 40px !important;
position: absolute !important;
top: -15px !important;
right: 20px !important;
}


.fa-caret-right {
  margin-right : 28px !important
}

.saveButtonTasklistWhite {
  width: 110px;
font-size: 15px;
background-color: white;
color: #6A7284 !important;
border-radius: 20px;
height: 30px;
padding: 0px !important;
border: #6A7284 1px solid;
margin: 0px !important
}

.saveButtonTasklistBlue {
  width: 110px;
font-size: 15px;
background-color: #194a96;
color: white !important;
border-radius: 20px;
height: 30px;
padding: 0px !important;
border: #6A7284 1px solid;
margin: 0px !important;

}

.saveButtonTasklistBlue:hover {

  border:2px solid white;
  
  }
  
  .saveButtonTasklistWhite:hover {
  
    border:2px solid #194a96 !important;
    color: #194a96 !important;
  
  }

  .removeBorder:focus {
    Border: 0px !important
  }

  .removeBorder {
    Border: 0px !important
  }

  #dropdown.dropdown-milestone .dropdown-container {
    max-width: 200px;
    margin-right: 20px;
  }

  #dropdown.dropdown-milestone .dropdown-container .dropdown-content {
    position: absolute !important;
    width: 300px;
    z-index: 100;
  }

  .MultiSelectClassTwo .dropdown-container {
    position: relative;
    outline: 0;
    border: #6A7284 1px solid !important;
}

.indent {
  margin-left: 10px;
}

.milestone-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  justify-items: center;
}

.milestone-item {
  position: relative;
}

.milestone-form-field {
  padding: 15px;
  border: #6A7284 1px solid;
  width: 100%;
  border-radius: 16px;
  padding-left: 23px;
  border: 1px #6A7284 solid;
}

.milestone-list .text-number-block-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 512px) {
  .milestone-list .text-number-block-row {
    flex-direction: column;
  }

  .milestone-list .text-number-block-row > div {
    max-width: 100%;
    margin: 7px 0;
  }
}

#milestones ::placeholder {
  color: #aaa;
  opacity: 1;
}

#milestones .fq-modal-header {
  text-align: left;
  background: initial;
  min-height: initial;
  padding-bottom: 5px;
}

#milestones .fq-modal-header h1 {
  color: rgb(106, 114, 132) !important;
  padding: 0 15px 10px;
  margin-bottom: 0;
}

#milestones .fq-modal-header p {
  padding: 0 15px 10px;
}

#milestones {
  background-color: #fff;
  height: 100%;
  margin: 0 0 0 auto !important;
  width: fit-content;
  padding: 10px;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

@media screen and (min-width: 1920px) {
  #milestones .mobilePadding {
    padding-left: 30px;
  }
}

#milestones .single-user {
  width: fit-content;
  padding: 5px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #fff;
  background-color: #194A96;
  border-radius: 16px;
}

#notifications ::placeholder {
  color: #aaa;
  opacity: 1;
}

#notifications .fq-modal-header {
  text-align: left;
  background: initial;
  min-height: initial;
  padding-bottom: 5px;
}

#notifications .fq-modal-header h1 {
  color: rgb(106, 114, 132) !important;
  padding: 0 15px 10px;
  margin-bottom: 0;
}

#notifications .fq-modal-header p {
  padding: 0 15px 10px;
}

#notifications {
  background-color: #fff;
  height: 100%;
  margin: 0 0 0 auto !important;
  width: fit-content;
  min-width: 40vw;
  padding: 10px;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

@media screen and (min-width: 1920px) {
  #notifications .mobilePadding {
    padding-left: 30px;
  }
}

#notifications .single-user {
  width: fit-content;
  padding: 5px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #fff;
  background-color: #194A96;
  border-radius: 16px;
}

#quill-format {
  width: 100%;
}

#quill-format > * {
  margin-bottom: 5px !important;
  padding: 0 !important;
}

#quill-format ul, #quill-format ol {
  margin-left: 15px;
  list-style: initial;
}

#quill-format a {
  color: #194A96
}

#quill-format h1 {
  font-size: 2em;
}

#quill-format h2 {
  font-size: 1.5em;
}

#quill-format h3 {
  font-size: 1.17em;
}

#quill-format h4 {
  font-size: 1em;
}

#quill-format h5 {
  font-size: 0.83em;
}

#quill-format h6 {
  font-size: 0.67em;
}

#quill-format .ql-align-center {
  text-align: center;
}
#quill-format .ql-align-justify {
  text-align: justify;
}
#quill-format .ql-align-right {
  text-align: right;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translate(15%, -10px) !important;
}

.slide-in {
  animation: slide-in 0.3s forwards;
  -webkit-animation: slide-in 0.3s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}
  
@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}
  
@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(100%); }
}

#milestones .fq-modal-header {
  padding-top: 5px;
}

#milestones.fq-modal-content {
  max-height: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 0;
}

#milestones .modal-content-wrapper .row.save .cancel {
  font-size: 15px;
  background-color: #fff;
  color: #194A96;
}

#milestones .modal-content-wrapper .row.save {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 15px;
}

#milestones .ql-toolbar.ql-snow, #milestones .ql-container.ql-snow {
  border: none;
}

#milestones .ql-toolbar.ql-snow {
  margin: 5px 10px;
  padding: 8px 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
  border-radius: 4px;
}

#milestones .ql-container.ql-snow .ql-editor::before {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-family: "Helvetica LT Std";
  color: #aaa;
}

#milestones .quill {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid #6A7284;
  border-radius: 16px;
  padding: 8px;
}

#notifications .fq-modal-header {
  padding-top: 5px;
}

#notifications.fq-modal-content {
  max-height: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 0;
}

#notifications.modal-content-wrapper{
  /* overflow-y: scroll; */
  overflow-x: hidden;
  height: 75vh;
}

#notifications .modal-content-wrapper .row.save .cancel {
  font-size: 15px;
  background-color: #fff;
  color: #194A96;
}

#notifications .modal-content-wrapper .row.save {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 15px;
}

#notifications .ql-toolbar.ql-snow, #notifications .ql-container.ql-snow {
  border: none;
}

#notifications .ql-toolbar.ql-snow {
  margin: 5px 10px;
  padding: 8px 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
  border-radius: 4px;
}

#notifications .ql-container.ql-snow .ql-editor::before {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-family: "Helvetica LT Std";
  color: #aaa;
}

#notifications .quill {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid #6A7284;
  border-radius: 16px;
  padding: 8px;
}

.react-datepicker__input-container {
  padding:1px
}
.removeBorder {
  height: 38px;
display: flex;
align-items: center;

}

.removeBorder:focus {
  caret-color: transparent;
  
       text-shadow: 0px 0px 1px #fff;
  cursor: pointer; 
}

.nohover:hover {
  border: 0px !important
}

.nohover:hover {
  border: 0px !important
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


.top10 {
 top : 10%
}

.top60 {
  top : 60%
 }

 #customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
 
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: center !important;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: white;

}

.iSprayBooth {margin: 0px !important}


.mobile-scroll-y-imp {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  overflow-y: scroll !important;
}


.TrainingModules {
  font-size: 14px;
font-weight: 500;
color: #194A96;
text-transform: uppercase;
margin: "0";
margin-top: 16px;
padding: "8px 0px";  
cursor: pointer;
}

@media screen  and (min-width: 1920px) {
  .nonFlex {
    display: block !important;
  }
} 



.StatusRow {
  display: flex;
  margin-bottom: 20px;
}

.BodyPicture .BrainhubCarouselItem {
  flex-direction: column;
}

.centered-text-overlay-bay-snake {
  position: absolute;
  color: #4f4343 !important;
  font-weight: bold;
  font-size: 60px;
  transform: rotate(-43deg);
  top: 40%;
  left: 33%;
  z-index: 1000;
}

.centered-text-overlay-bay-snake-mobile {
  position: absolute;
  color: #4f4343 !important;
  font-weight: bold;
  font-size: 60px;
  transform: rotate(-70deg);
  top: 40%;
  left: 0%;
  z-index: 1000;
}

.enoughSpace {
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin-right: 20px;
  height: 30px;
 
}

.spanVersioning {
  display: flex;
  padding-left:1px;
  
}

.max-w-440 {
  max-width: 440px;
}

.multiClass {

  width: 100%
}

.multiClass .custom-select__menu{
  z-index: 1000;

}

.custom-option {
  transition: background 60ms;
}
.custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
}
.custom-option.custom-select__option--is-focused {
  background: none;
}
.custom-option.custom-select__option--is-selected {
  background: #2684FF;
}

.bg-grey {
  pointer-events: none !important;
  background-color: #B5B5B5;
}

.fa-wrench::before {
  pointer-events: all!important;
}

.custom-select__single-value {
  color: #6A7284 !important;

} 

.pointer {
  cursor: pointer;
  
} 

strong { font-weight: bold !important; }
u {
  text-decoration-line: underline !important;}
em {
  font-style: italic  !important;
}


.card-body {
  overflow: initial !important;
}



.tagBay {
  width: 38px;
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center !important;
  user-select: all;
}

.fixHeight{
  height: 250px !important;
  width: 250px !important;  
}

.saveButtonTasklistSavebtn {
  background: #194A96;
}

.saveButtonTasklistNotSave{
  background: grey;
}

.notclick {
  cursor: unset !important;
}

.border-bottom{
  border-bottom:1px solid rgb(233, 236, 239);
}

.z-1000{
  z-index: 1000;
}

.input-search-field .ant-input-affix-wrapper{
  height: 100% !important;
  border: 0px solid #fff !important;
  border-right-width: 0px !important;
  border-right-style: transparent !important;
}

.input-search-field .ant-input-affix-wrapper:focus, .input-search-field .ant-input-affix-wrapper-focused{
  outline: none !important;
  box-shadow: none !important;
}

.input-search-field .ant-btn-icon-only{
  background: #194A96 !important;
}

.input-search-field .anticon-search svg,.input-search-field .anticon-loading svg{
  fill: #fff;
}

/* MarkerClusterer styles */
.mc-cluster {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  background-color: #1e90ff;
  border-radius: 50%;
}

.mc-cluster:hover {
  background-color: #0d47a1;
}

.mc-cluster span {
  position: relative;
  top: -2px;
}

.input-project-wrapper .ant-input:focus, .input-project-wrapper .ant-input:hover, .input-project-wrapper .ant-input-focused{
  box-shadow: none !important;
  outline: none !important;
  border: 0px !important;
  border-width: 0px !important;
}

.ant-pagination-item,.ant-pagination-next .ant-pagination-item,.ant-pagination-prev .ant-pagination-item,.ant-pagination-item-link{
  border-radius: 50% !important;
  border: 0 !important;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.ant-pagination-item-link{
  background: #EDEFEC !important;

}
.ant-pagination-next:hover .anticon svg{
  fill: #000
}


.ant-pagination-item-active{
  background-color: #0d47a1 !important;
  color: #fff !important;
}

.project-del-icon{
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  z-index: 999;
}
.project-recover-icon{
  position: absolute;
  right: 18px;
  top: 44px;
  cursor: pointer;
  z-index: 999;
}
.project-recover-icon .fa-repeat{
  font-size: 22px;
}
.project-link{
  font-size: 16px;
  font-weight: bold;
}
.project-link a{
  color: inherit;
  display: block;
}
.color-black{
  color: #000;
}
.card .card-heading{
  font-size: 20px;
  color: #6A7284;
  line-height: 24px;
  font-weight: 700;
}
.card .card-heading{
  font-size: 20px;
  color: #6A7284;
  line-height: 24px;
  font-weight: 700;
}

.dashboard-3 .leftpanel-menu{
  background: #fff !important;
}

.kpi-upload-btn{
  border-radius: 16px;
  border: 1px solid #666;
  padding: 10px 20px;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
}
.kpi-upload-btn .label-kpi{
  font-family: inherit !important;
  text-transform: uppercase;
}
.kpi-upload-btn .label-kpi i{
  padding-left: 10px;
}
.kpi-upload-btn input{
  display: block !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-35%);
  opacity: 0;
    visibility: visible;
    cursor: pointer;
}
.kpi-card-panel .stats{
  font-size: 22px;
  font-weight: 500;
}
.kpi-progress-cards{
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.kpi-card-panel{
  opacity: 1;
  cursor: pointer;
  position: relative;
}
.kpi-card-panel.active{
  opacity: 1;
}
.kpi-card,.kpi-card-panel {
  border-right: 1px solid #D3D3D3
}
.kpi-guage .ant-progress-text{
  font-size: 22px;
  color:#6A7284 !important;
}
.kpi-card-panel .card-body{
  padding-left: 0px !important;
}
.kpi-v2-row .last-col .kpi-card{
  border-right: 0px solid #fff;
}
.kpi-v2-row{
  display: grid;
  grid-template-columns: repeat(6,1fr);
}
.kpi-v2-row .kpi-single{
  padding: 0px 8px;
  margin-bottom: 20px;
}
.kpi-v2-row .kpi-single .kpi-card .card-c-heading-2{
  min-height: 40px;
}
.kpi-card-panel .text-stats{
  position: absolute;
  bottom: 26px;
  right: 16px;
}
.kpi-card-panel .card-c-heading-2,.kpi-card .card-c-heading-2{
  font-size: 20px;
  font-weight: bold;
}
.kpi-card .card-text{
  font-size: 14px;
  color: #6A7284;
}
.kpi-card-panel.red-card svg{
  fill: #BE0412;
}
.kpi-card-panel.green-card svg{
  fill: #1C822C;
}


.bg-transparent {
  background: transparent;
}

.no-border {
  border: none;
}

.notifications-header__subtitle {
  padding: 0 15px 10px;
  margin-bottom: 0;
}

.notifications-dropdown-content {
  background: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.notifications-dropdown-content .ant-dropdown-menu {
  box-shadow: none;
}

.projects-wrapper{
  min-height: 400px;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    color: rgba(255, 255, 255, 0.55);
}

.tasklist-picture-slider .item{
  height: 160px;
}


.audit-wrapper{
  color: rgb(106, 114, 132) !important;
}
.card-custom .card.audit-wrapper .card-c-heading,.audit-wrapper .small-text{
  color: rgb(106, 114, 132) !important
}

.ml--15{
  margin-left: -15px !important;
}
.mr--15{
  margin-right: -15px !important;
}

.project-heading-xl{
  font-family: 'Roboto';
  color: #6A7284;
  font-size: 6.25vw;
  font-style: normal;
  font-weight: 300;
  line-height: 5.25vw;
  margin-bottom: 22px !important;
}
.card-custom{
  /* flex-wrap: nowrap; */
}
.card-custom .col {
  flex-basis: 0 !important;
  flex-grow: 1 !important;
  max-width: 100%;
  color: #fff;
  height: 175px;
}
.card-custom .col .card-c-heading{
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0px;

}
.card-custom .col.last{
  color: #6A7284 !important;
}
.card-custom .col.last .card-c-heading {
  color: #6A7284 !important;
}
.milestone-row{
  display: flex;
  gap: 0px;
}
.milestone-row .col{
  flex-basis: 100%;
}
.business-analytics-section{
  gap: 16px;
}
 .business-analytics-section .row{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.project-col-1 .gap-16{
  gap: 16px;
  height: 100%;
}

.projects-kpi-outer{
  padding: 0px !important;
}
.projects-kpi-outer .row{
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 100%;
}
.projects-kpi-inner-cards .kpi-cards-stats{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.projects-kpi-inner-cards .kpi-col{
  border-bottom: 1px solid #D3D3D3;
  position: relative;
  padding-left: 22px !important;
  padding-right: 0px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;


}
.projects-kpi-inner-cards .kpi-col:nth-child(3),.projects-kpi-inner-cards .kpi-col:nth-child(4){
  border-bottom: 0px solid #D3D3D3;
  padding-top: 50px;
}

.projects-kpi-inner-cards .kpi-col:nth-child(3):after,.projects-kpi-inner-cards .kpi-col:nth-child(1):after{
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #D3D3D3;
  width: 1px;
  height: 97px;
}

.projects-kpi-inner-cards .kpi-cards-stats .stats{
  margin-top: 24px !important;
}

.projects-kpi-inner-cards .kpi-col:nth-child(3),.projects-kpi-inner-cards .kpi-col:nth-child(4){
  padding-top: 32px !important;
}

.two-column-height-wrapper .card-height{
  height: auto !important;
  min-height: auto !important;
}
.two-column-height-wrapper{
  height: 100%;
}

.projects-kpi-inner-cards .kpi-col .kpi-cards-stats{
  position: relative;
}
.projects-kpi-inner-cards .kpi-col .kpi-cards-stats .stats-icon{
  position: absolute;
  right: 15px;
  bottom: 0;
  display: flex;
}
.projects-kpi-inner-cards .kpi-col .kpi-cards-stats .stats-icon .span-text{
  margin-left: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.9px;
  color: #6A7284;

}
.projects-kpi-inner-cards .kpi-col .kpi-cards-stats .stats-icon .icon-img svg,.projects-kpi-inner-cards .kpi-col .kpi-cards-stats .stats-icon .icon-img{
  max-width: 24px;
}

.projects-kpi-inner-cards .kpi-col .kpi-cards-stats.green svg path.fill{
  fill: #1CC435;
}
.projects-kpi-inner-cards .kpi-col .kpi-cards-stats.green .span-text{
  color: #1CC435;
}

.projects-kpi-inner-cards .kpi-col .kpi-cards-stats.red svg path.fill{
  fill: #BE0312;
  transform: rotate(180deg);
    transform-origin: center;
}
.projects-kpi-inner-cards .kpi-col .kpi-cards-stats.red .span-text{
  color: #BE0312;
}




.pill-container{
  margin-top: 16px;
  margin-left: -15px;
  margin-right: -15px;
  color: #fff;
}
.audit-score-pill .ant-progress-outer{
  margin-right: 0px;
  padding-right: 0px;
}
.pill-container .ant-progress{
  color: #fff;
}
.audit-score-pill .ant-progress-inner{
  background: #fff;
  color: #fff;
  height: 76px;
}

.audit-score-pill .ant-progress-bg{
  height: 76px !important;
}

.audit-score-pill .ant-progress-text{
  font-size: 22px;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0.75px;
  position: absolute;
  top: 50%;
  left: 220px;
  transform: translateY(-50%);
}

.pill-container .wrapper-top{
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  z-index: 6;
}

.pill-container .wrapper-top .heading{
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.pill-container .wrapper-top .small-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.5px;
}

.bg-pill-red .ant-progress-bg{
  background-color: #BE0312 !important;
}
.bg-pill-orange .ant-progress-bg{
  background-color: #F99F1C  !important;
}
.bg-pill-green .ant-progress-bg{
  background-color: #1CC435 !important;
}
.bg-pill-dark-green .ant-progress-bg{
  background-color: #1F8E30 !important;
}
.pill-container .text-white .heading,.pill-container .text-white .small-text,.pill-container .text-white .ant-progress-text{
  color: #fff;
}
.pill-container .text-dark .heading,.pill-container .text-dark .small-text,.pill-container .text-dark .ant-progress-text{
  color: #e0e0e0;
}
.pill-container .text-full-dark .heading,.pill-container .text-full-dark .small-text,.pill-container .text-full-dark .ant-progress-text{
  color: #6A7284;
}
.pill-container{
  background: #FFF;
box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
border-radius: 39px;
}
.project-image-wrapper{
  margin-top: 10px;
}
.kpi-guage{
  max-width: 80%;
}
.card-custom.gap-c-16{
  gap: 16px !important;
}
/* MEDIA QUERIES */

@media screen and (min-width: 320px) {
  .ant-picker-panels {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .ant-picker-panels {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .ant-picker-panels {
    flex-direction: row;
    display: flex;
    width: 100%;
  }
}
@media screen and (max-width: 1024px){
  .project-heading-xl{
    margin-left: 0px !important;
  }
  .profile-content{
    padding-left: 0px !important;
  }
  .pill-container {
    margin-left: 0px;
    margin-right: 0px;
  }
  .milestone-row,.milestone-row .col{
    margin-top: 0px !important;
  }
  .milestone-row .card{
    margin-bottom: 0px !important;
  }
  .row,.mr--15,.ml--15{
    margin-left: 0px !important;
    margin-right: 0px!important;
  }
  .kpi-inner .card-chart {
    margin-left: 15px;
    margin-right: 15px;
  }
  .card-custom .col {
    min-width: 260px;
  }
  .kpi-v2-row{
    display: grid;
    grid-template-columns: repeat(4,1fr);
  }
  .milestone-row .milestone-row-inner{
    width: 100%;
    flex-basis: 100%;
    margin-top: 0px !important;
  }
  .milestone-row {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
  .milestone-row{
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 768px){

  .project-heading-xl{
    margin-bottom: 10px !important;
  }
  .pill-container .wrapper-top .heading {
    font-size: 14px;
    line-height: 16px;
  }
  .pill-container .wrapper-top .small-text {
    font-size: 12px;
    line-height: 16px;
  }
  .audit-score-pill .ant-progress-text{
    font-size: 24px !important;
  }

  .kpi-guage{
    max-width: 60%;
  }

  .project-image-wrapper {
    margin-top: 0px;
  }
  .project-head-col-2{
    margin-left: 6px;
  }
  .audit-score-pill .ant-progress-text{
    top: 70%;
    left: 18px;
  }
  .pill-container .wrapper-top{
    top: 30%;
  }

  .kpi-v2-row .kpi-single:nth-child(2n) .kpi-card,.kpi-v2-row2 .kpi-card-panel{
    border-right: 0px solid #fff !important
  }
  .kpi-v2-row{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  .kpi-guage .ant-progress-text{
    font-size: 14px;
  }
  .kpi-dashboard .card-c-heading-2 {
    font-size: 16px;
  }
  .mobile-flex-start{
    flex-direction: column;
    align-items: flex-start !important;
    padding-left: 0px !important;
  }
  .card-filter-wrappers {
    position: absolute;
    z-index: 9999;
    right: 0px;
    top: 40px;
  }
  .kpi-progress-cards{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .kpi-progress-cards .card .stats {
    font-size: 28px;
    line-height: 28px;
  }
}


@media screen and (max-width: 415px){
  .saveButtonTasklist,.saveButtonTasklistSave{
    width: 50px !important;
    margin: 0px 5px;
    padding: 5px 10px;
  }

  #milestones {
    width: 100%;
  }

  #notifications {
    width: 100%;
  }

  .kpi-guage{
    max-width: 100% !important;
  }
}


@media screen and (max-width: 400px) {
  .projects-kpi-inner-cards .kpi-col{
    padding-left: 10px !important;
  }
}
